import '../css/pages/footer.css'

function Footer() {
    return(
        <footer>
            <p className='footer-text'>Réalisé par Fanny Alix</p>
        </footer>
    )
}

export default Footer